import _ from 'underscore';
import Vue from 'vue';
import Dialog from '@/components/dialog';
import ProfileGroups from '@/components/ProfileGroups.vue';

const groups = async ({ profile }) => {
  const Elem = Vue.extend(ProfileGroups);
  const _dialog = new Dialog({
    title: `Grupos ( ${profile.name} )`,
    height: '800',
  });
  _dialog.open();
  _dialog.appendPage(Elem, { profile });
};

export default {
  groups,
};
